<template>
    <div class="w100">
        <div class="w100 bgc_fff" style="height: 64px;">
            <div class="cI_top">
                <div class="text-30 c_3291F8">坪山天安人才</div>
            </div>
        </div>
        <div class="cI_con bgc_fff ">
            <div class="w100">
                <span class="text-18 text-bold" style="color: #2e395a;">完善信息</span>
            </div>
            <el-form ref="ruleFormRef" :model="cIFrom" :rules="cIRule" label-width="120px"
                style="width: 670px;margin-top: 36px;" :disabled="disabledLog">
                <el-form-item label="公司全称:" prop="cName" show-message>
                    <el-input v-model="cIFrom.cName" type="text" placeholder="请填写公司全称" />
                </el-form-item>
                <el-form-item label="公司简称:" prop="csName" show-message>
                    <el-input v-model="cIFrom.csName" type="text" placeholder="请填写公司简称" />
                </el-form-item>

                <el-form-item label="公司Logo:" prop="cLogo">
                    <el-upload class="dl-avatar-uploader-min square" :action="uploadUrl" :with-credentials="true" :show-file-list="false"
                        :on-success="handleUpImage" list-type="picture">
                        <el-image v-if="cIFrom.cLogo" :src="cIFrom.cLogoUrl" class="avatar"></el-image>
                        <div v-else class="uploader-btn flex align-center justify-center">
                            <img src="@/assets/icon_add.png" style="display: block;width: 40px;height: 40px;" alt="">
                        </div>
                        <template #tip>
                            <div class="el-upload__tip">logo要求：不能为二维码，营业执照，门头照片等</div>
                        </template>
                    </el-upload>
                </el-form-item>

                <el-form-item label="公司介绍:" prop="cDescribe">
                    <div class="w100 bs">
                        <el-input v-model="cIFrom.cDescribe" placeholder="请填写公司介绍" type="textarea" :maxlength="10000"
                            :rows="8" show-word-limit />
                    </div>
                </el-form-item>

                <el-form-item label="公司行业:" prop="cHy">
                    <el-select v-model="cIFrom.cHy" filterable allow-create class="w100" :reserve-keyword="false"
                        placeholder="请选择公司行业">
                        <el-option v-for="item in cHy" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>

                <el-form-item label="所在城市:" prop="cCity">
                    <el-cascader placeholder="请选择所在城市" class="w100" v-model="cIFrom.cCity" :options="categoryOp"
                        filterable />
                </el-form-item>

                <el-form-item label="详细地址:" prop="address" show-message>
                    <el-input v-model="cIFrom.address" type="text" placeholder="请填写公司详细地址" />
                </el-form-item>

                <el-form-item label="公司规模:" prop="cGm">
                    <el-select v-model="cIFrom.cGm" filterable allow-create class="w100" :reserve-keyword="false"
                        placeholder="请选择公司规模">
                        <el-option v-for="item in cGm" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>

                <el-form-item label="公司性质:" prop="cXz">
                    <el-select v-model="cIFrom.cXz" filterable allow-create class="w100" :reserve-keyword="false"
                        placeholder="请选择公司性质">
                        <el-option v-for="item in cXz" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>

                <el-form-item label="发展阶段:" prop="cFzjd">
                    <el-select v-model="cIFrom.cFzjd" filterable allow-create class="w100" :reserve-keyword="false"
                        placeholder="请选择发展阶段">
                        <el-option v-for="item in cFzjd" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>

                <el-form-item label="营业执照:" prop="cYyzz">
                    <el-upload class="dl-avatar-uploader-min square" :action="uploadUrl"  :with-credentials="true"  :show-file-list="false"
                        :on-success="handleUpImageYyzz" list-type="picture">
                        <el-image v-if="cIFrom.cYyzz" :src="cIFrom.cYyzzUrl" class="avatar"></el-image>
                        <div v-else class="uploader-btn flex align-center justify-center">
                            <img src="@/assets/icon_add.png" style="display: block;width: 40px;height: 40px;">
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div class="flex align-center justify-center mt_40"
                v-if="!userInfo.companyInfo || (userInfo.companyInfo && userInfo.companyInfo.state != '1')">
                <el-button type="primary" @click="comit(ruleFormRef)"
                    :disabled="userInfo.companyInfo && userInfo.companyInfo.state == '0'">
                    {{ userInfo.companyInfo && userInfo.companyInfo.state == '0' ? '审核中' : '提交' }}</el-button>
            </div>
        </div>

        <div class="w100 bs flex align-center justify-center"
            style="height: 60px;background: #fff;color: #8c8c8c;font-size: 12px;">
            <span>© 版权所有 深圳市智垒网科技术有限公司 备案号：</span><a style="color: #8c8c8c;"
                href="https://beian.miit.gov.cn/#/Integrated/index" rel="noopener noreferrer"
                target="_blank">粤ICP备17071033号</a>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, ref } from 'vue';
import { getUserInfo, saveHunterCompany, listDict, listCity } from '@/util/apis'
import { ElLoading, ElMessage } from 'element-plus'
export default defineComponent({
    name: '',
    setup() {
        var ruleFormRef = ref(null);

        var loading: { close: () => void; } | null = null;
        
        const state = reactive({
            cIFrom: {
                cName: '',
                csName: '',
                cLogo: '',
                cLogoUrl: '',
                cHy: '' as any,
                cCity: '' as any,
                cGm: '' as any,
                cXz: '' as any,
                cFzjd: '' as any,
                cYyzz: '',
                cYyzzUrl: '',
                cDescribe: '',
                address: ''
            },
            cIRule: {
                cName: [{ required: true, message: '请填写公司全称', trigger: 'change' }],
                csName: [{ required: true, message: '请填写公司简称', trigger: 'change' }],
                cLogo: [{ required: true, message: '请上传公司Logo', trigger: 'change' }],
                cHy: [{ required: true, message: '请选择公司行业', trigger: 'change' }],
                cCity: [{ required: true, message: '请选择所在城市', trigger: 'change' }],
                cGm: [{ required: true, message: '请选择公司规模', trigger: 'change' }],
                cXz: [{ required: true, message: '请选择公司性质', trigger: 'change' }],
                cFzjd: [{ required: true, message: '请选择发展阶段', trigger: 'change' }],
                cYyzz: [{ required: true, message: '请上传公司营业执照', trigger: 'change' }],
                cDescribe: [{ required: true, message: '请填写公司介绍', trigger: 'change' }],
                address: [{ required: true, message: '请填写公司详细地址', trigger: 'change' }],
            },
            uploadUrl: process.env.VUE_APP_API_URL + '/sys/file/upload.do',
            kwOp: [{
                value: 'HTML',
                label: 'HTML',
            },
            {
                value: 'CSS',
                label: 'CSS',
            }],
            categoryOp: [] as any,

            cXz: [] as any,
            cGm: [] as any,
            cFzjd: [] as any,
            cHy: [] as any,
            disabledLog: false,
            userInfo: {} as any
        })
        loading =
            ElLoading.service({
                lock: true,
                text: '加载中...',
                background: 'rgba(0, 0, 0, 0.7)',
            })
        var doArr = ['company_nature', 'company_scale', 'company_stage', 'company_industry'].map(item => listDict({ type: item }));
        var gUserInfo = {} as any;
        Promise.all([...doArr, listCity({})]).then(resList => {
            console.log('resList==', resList)
            //公司性质、公司规模、发展阶段、公司行业
            state.cXz = resList[0].data;
            state.cGm = resList[1].data;
            state.cFzjd = resList[2].data;
            state.cHy = resList[3].data;
            state.categoryOp = resList[4].data;
            getUI();
            loading!.close()
        })

        const getUI = () => {
            getUserInfo({}).then(res => {
                gUserInfo = res.data;
                state.userInfo = res.data;
                if (gUserInfo.companyInfo) {
                    state.cIFrom.cName = gUserInfo.companyInfo.name;
                    state.cIFrom.csName = gUserInfo.companyInfo.simpleName;
                    state.cIFrom.cHy = Number(gUserInfo.companyInfo.industry);
                    state.cIFrom.cCity = [gUserInfo.companyInfo.addressCode.substring(0, 2) + '0000', gUserInfo.companyInfo.addressCode.substring(0, 4) + '00', gUserInfo.companyInfo.addressCode];
                    state.cIFrom.cGm = Number(gUserInfo.companyInfo.scale);
                    state.cIFrom.cXz = Number(gUserInfo.companyInfo.nature);
                    state.cIFrom.cFzjd = Number(gUserInfo.companyInfo.stage);
                    state.cIFrom.cYyzz = gUserInfo.companyInfo.license;
                    state.cIFrom.cYyzzUrl = gUserInfo.companyInfo.license;
                    state.cIFrom.cDescribe = gUserInfo.companyInfo.description;
                    state.cIFrom.address = gUserInfo.companyInfo.address;
                    state.disabledLog = true;
                    // state.cIFrom.cLogo
                    // state.cIFrom.cLogoUrl
                }
            })
        }

        const methods = {
            handleUpImage(res: any) {
                state.cIFrom.cLogo = res.data.id;
                state.cIFrom.cLogoUrl = res.data.ext.fullUrl;
            },
            handleUpImageYyzz(res: any) {
                state.cIFrom.cYyzz = res.data.id;
                state.cIFrom.cYyzzUrl = res.data.ext.fullUrl;
            },
            async comit(formEl: any) {
                if (!formEl) return
                await formEl.validate((valid: any, fields: any) => {
                    if (valid) {
                        loading =
                            ElLoading.service({
                                lock: true,
                                text: '提交资料中...',
                                background: 'rgba(0, 0, 0, 0.7)',
                            })
                        saveHunterCompany({
                            name: state.cIFrom.cName,
                            simpleName: state.cIFrom.csName,
                            license: state.cIFrom.cYyzz, //营业执照
                            description: state.cIFrom.cDescribe,//公司介绍
                            nature: state.cIFrom.cXz, //性质
                            scale: state.cIFrom.cGm,//规模
                            industry: state.cIFrom.cHy,//行业
                            addressCode: state.cIFrom.cCity[2],//地址编码
                            address: state.cIFrom.address, //详细地址
                            stage: state.cIFrom.cFzjd, //发展阶段
                            logo: state.cIFrom.cLogo
                        }).then(res => {
                            loading!.close()
                            if (res.code == 0) {
                                getUI();
                            }
                        }).catch(f => {
                            loading!.close()
                        })
                    }
                })
            },

        }
        return {
            ...toRefs(state), ...toRefs(methods), ruleFormRef
        }
    }
})
</script>

<style scoped>
@import "./index.css";
</style>